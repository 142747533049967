.main {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	text-align: center;
	width: 100%;
}

h3 {
	color: #f8f9fa;
	font-weight: 700;
	letter-spacing: 0.25rem;
	text-transform: uppercase;
}

h4 {
	color: #f8f9fa;
	font-weight: 500;
	letter-spacing: 0.25rem;
	font-size: 0.8rem;
	text-transform: uppercase;
}

.about-subheading {
	color: #f8f9fa;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 2rem;
	max-width: 500px;
	text-align: left;
}

.about-body {
	color: #f8f9fa;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.7;
	margin-top: 20px;
	text-align: left;
	margin-bottom: 0.5rem;
}

.content-underline:link,
.content-underline:visited {
	color: #f8f9fa;
	border-bottom: #93c5fd 4px solid;
}

.content-underline:hover,
.content-underline:active {
	border-bottom: #f9a8d4 4px solid;
	cursor: pointer;
}

aside {
	width: 100%;
	height: 100%;
}

.tech-stack-grid {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, minmax(120px, 1fr));
	gap: 0.5rem;
}

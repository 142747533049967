nav {
	width: 100%;
	height: 80px;
	padding: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

ul {
	display: flex;
	justify-content: center;
	gap: 4rem;
}

nav ul li {
	color: #fafafa;
	display: inline-block;
	font-size: 1.15rem;
	font-weight: 500;
}

nav ul li a:hover {
	cursor: pointer;
	padding-bottom: 2px;
	border-bottom: #93c5fd 4px solid;
}

/* Large Screens */
@media only screen and (min-width: 1024px) {
	nav {
		justify-content: flex-start;
	}

	nav ul li {
		font-size: 1.25rem;
	}
}

.header {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	text-align: left;
	width: 100%;
	margin-bottom: 2rem;
}

.header h1 {
	padding-bottom: 1.25rem;
}

.header h4 {
	color: #f8f9fa;
	font-weight: 700;
	letter-spacing: 0.25rem;
	text-transform: uppercase;
}

.container {
	width: 100%;
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	margin-bottom: 2rem;
}

.header h1 {
	padding-bottom: 1.25rem;
}

.header h4 {
	color: #f8f9fa;
	font-weight: 700;
	letter-spacing: 0.25rem;
	text-transform: uppercase;
}

.form-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
}

.form-container h4 {
	color: #f8f9fa;
	font-weight: 700;
	letter-spacing: 0.25rem;
	text-transform: uppercase;
}

.form-container form {
	background-color: rgb(25, 25, 25);
	border-radius: 20px;
	padding: 1rem;
	width: 120%;
	text-align: left;
}

.form-container form label {
	color: #aab4bd;
	width: 100%;
}

@media only screen and (min-width: 640px) {
	.form-container form {
		padding: 2rem;
		width: 90%;
		max-width: 600px;
	}
}

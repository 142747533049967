* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #111;
	overflow: auto;
}

#root {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 350px;
}

.container {
	width: 100%;
	max-width: 1200px;
}

#about {
	padding: 2rem;
}

#work {
	padding: 2rem;
}

#contact {
	padding: 2rem;
}

ion-icon {
	font-size: 32px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.card {
	width: 100%;
	position: relative;
	border-radius: 0.375rem;
	box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	transition: 0.4s ease-out;
}

.card:hover {
	transform: translateY(20px);
}

.card:hover:before {
	opacity: 1;
}

.card:hover .info,
.card:hover .actions {
	opacity: 1;
	transform: translateY(0px);
}

.card:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 0.375rem;
	background: rgba(0, 0, 0, 0.6);
	z-index: 2;
	transition: 0.5s;
	opacity: 0;
}

.card img {
	width: 100%;
	border-radius: 0.375rem;
}

.card .info {
	width: 330px;
	position: absolute;
	top: 30%;
	left: 50%;
	opacity: 0;
	margin-left: -165px;
	text-align: center;
	z-index: 3;
	color: white;
}

.card .info h5 {
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 0.5rem;
	line-height: 1;
}

@media only screen and (min-width: 440px) {
	.card {
		width: 100%;
	}

	.card .info {
		width: 380px;
		margin-left: -190px;
	}
}

/* @media only screen and (min-width: 540px) {
	.card {
		width: 100%;
	}

	.card .info {
		width: 400px;
		margin-left: -200px;
	}
} */

@media only screen and (min-width: 640px) {
	.card {
		width: 450px;
	}

	.card .info {
		width: 480px;
		margin-left: -240px;
	}
}

@media only screen and (min-width: 1024px) {
	.card {
		width: 50%;
	}
	.card .info {
		width: 400px;
		margin-left: -200px;
	}
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	min-width: 350px;
	padding: 2rem;
}

.col {
	width: 115%;
}

.col h1 {
	padding-bottom: 1.25rem;
}

.col h2 {
	color: #fafafa;
}

.col .links-container {
	margin-bottom: 1rem;
}

.col .links-container ion-icon {
	color: #f8f9fa;
	margin: 0.4rem;
}

footer {
	text-align: center;
	width: 100%;
	min-width: 350px;
	color: #f8f9fa;
}

footer small {
	display: block;
	font-weight: 600;
	margin-bottom: 0rem;
}

.copyright {
	color: #f8f9fa;
	display: block;
	padding-bottom: 0rem;
	font-weight: 400;
	font-size: 0.5rem;
	padding-bottom: 2rem;
}
